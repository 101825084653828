.reporting-filter-panel{
  border: none !important;
  box-shadow: none !important;
}

.reporting-filter-title{
  padding-left: 0rem !important;
  padding-bottom: 0rem !important;
  color: $dark-gray !important;
}

.reporting-filter-text-top{
  font-family: $standard !important;
  font-size: .8rem !important;
  padding-top:.2rem;
  font-weight: 300;
}

.reporting-filter-select{
  width:25vw;
  font-size: 14px;
}

.MuiIconButton-root {

  &.reporting-radio{
    color: #4285F4 !important;
    padding: 0rem !important;
    font-size: .5rem !important;
  }
}
.MuiTypography-body1, .reporting-filter-picker-text{
  color: $dark-gray;
  font-size: .75rem !important;
  font-family: $standard !important;
}

.reporting-panel-details{
  padding-top: 0rem !important;
  display: block !important;
}

.reporting-filter-text{
  color: $dark-gray;
  font-size: .8rem !important;
  font-family: $standard !important;
  font-weight: 400;
}

.reporting-date-filter{
  flex-direction: column;
  text-align: center;
}

.reporting-submit-filter{
  color: $blue;
  font-size: 10px !important;
  font-family: $standard !important;
  font-weight: 300;
  border: 1px solid $blue;
  padding: .25rem;

  &:hover{
    color: $blue;
  }
}

@media only screen and (max-width: 800px) {
  .reporting-filter-select{
    width:50vw;
    font-size: 0.75rem;
  }
}