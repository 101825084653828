// The existing visa modal
.vc__existing-visa-modal {
  z-index: 1200;
  overflow-y: scroll;

  > div {
    background-color: rgba(231, 231, 231, 0.2) !important;
  }
}

// The content within the existing visa modal
.vc__existing-visa-modal-content {
  overflow-y: auto;
  background-color: $white;
  height: 90%;
  width: 70%;
  padding: 2rem;
  position: absolute;
}

.vc__existing-visa-node {
  height: 90%;
}


// The 'X' Icon
.MuiSvgIcon-root.vc__modal-close-icon {
  font-size: 2rem;
  color: $dark-gray;
  cursor: pointer;
}

.vc__existing-visa-result-screen {
  height: 90%;
}

.vc__contact-immigration {
  color: $yellow;
}

.vc__visa-radio {
  color: $gray;
}

.visa-radio-form > .MuiRadio-root {
  padding: 0rem !important;
  color: $gray !important;
}

.visa-radio-form > .MuiTypography-root {
  color: $gray !important;
  font-family: $standard !important;
  font-size: 1rem !important;
  letter-spacing: 1px;
}

.MuiRadio-colorPrimary.Mui-checked{
  color: $blue !important;
}
