.navigation-bar {
  box-shadow: $standard-dropshadow;
  height: 4.5rem;
  background-color: $white;
  margin-bottom: 5px;
}

.MuiAppBar-root{
  z-index: 1300 !important;
}

.app-container {
  margin-top: 5rem;
}

.client-logo {
  background-image: url('/images/google.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  height: 3.5rem;
  width: 6rem;
}

.navigation-help {
  font-size: .9rem;
  margin-bottom: 1rem;
  color:$dark-gray;
  font-weight: 400;
  font-family: $standard;
  letter-spacing: 0.04rem;
  cursor: pointer;
}

.logout-button {
  font-family: $standard;
  color: $blue;
  font-size: .9rem;
  padding-top: 0.2rem;
}

.navigation-help-icon{
  color: $dark-gray;
  font-size: 1.5rem !important;
  margin-left: 0.6rem;
}

.navigation-profile-image{
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  cursor: pointer;
}

.defect-form-link {
  cursor: pointer;
}

@media print{    
  .navigation-bar {
    display: none !important;
    margin-top: -2rem !important;
  }

  .navigation-help {
    display: none !important;
  }
}
