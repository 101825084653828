@font-face {
  font-family: 'Product-Sans';
  src: local('Product-Sans'), url(../src/fonts/Product-Sans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Product-Sans-Bold';
  src: local('Product-Sans-Bold'), url(../src/fonts/Product-Sans-Bold.woff) format('woff');
}

@import './styles/Variables/Variables.scss';
@import './styles/NavigationBar/NavigationBar.scss';
@import './styles/RequirementQuestion/RequirementQuestion.scss';
@import './styles/HorizontalStepper/HorizontalStepper.scss';
@import './styles/Sidebar/Sidebar.scss';
@import './styles/Results/Results.scss';
@import './styles/Results/ShareResult.scss';
@import './styles/SnackBarWarning/SnackBarWarning.scss';
@import './styles/HelpAndSupport/HelpAndSupport.scss';
@import './styles/Reporting/Reporting.scss';
@import './styles/ExistingVisa/ExistingVisa.scss';
@import './styles/LandingPage/LandingPage.scss';
@import './styles/LandingPage/announcement.scss';
@import './styles/SessionExpired/SessionExpired.scss';

@import './styles/bootstrap-customisation/imports.scss';
