.results-success-check{
  font-size: 5rem !important;
  color: $green;
  margin-right: 1.75rem;
}

.MuiCircularProgress-root{
  color: $blue !important;
}

.loader {
  &.results {
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.hidden-label{
  color: $white;
  opacity: 0;
  font-size: 1px;
  font-family: $standard;
  line-height: 0rem !important;
  margin-bottom: 0rem;
}

.change-acceptance-date{
  margin-top: -1rem !important;
}

.results-failure-x{
  font-size: 5rem !important;
  color: $red;
  margin-right: 1.75rem;
}

.results-close-button{
  color:$blue;
  font-size: 0.9rem;
  padding-top: 1.9rem;
  font-family: $standard;

  &:hover{
    text-decoration: none;
  }
}

.vc__summary-divider {
  box-sizing: border-box;
  height: 1px;
  max-width: 768px;
  border: 0px;
  border-top: 1px solid #B8B8B8;
}

.vc_align-right {
  flex-direction: row;
  margin-left: auto;
}

.vc_results-return-button {
  background-color: $blue;
  color:$white;
  font-size: 0.8rem;
  letter-spacing: 1px;
  box-shadow: 0px 3px 4px -2px $light-gray;
  border: none;
  border-radius: .2rem;
  font-family: $standard;
  min-width: 225px;
  height: 44px;

  &:hover {
    box-shadow: 0px 5px 6px -2px $light-gray;
    color: $white;
  }

  &:disabled {
    color: $gray;
  }
}

.asessment-result-info{
  flex-direction: column;
  margin-top: 5rem;
}

.asessment-result-info-text{
  color:$dark-gray;
  font-size: .75rem;
  font-weight: 300;
  font-family: $standard;
  margin: 0rem;
  margin-top: .5rem;
}

.results-eligibility-heading{
  font-size: 3.5rem;
  color: $dark-gray;
  font-family: $headings;
  font-weight: 600;
  margin-bottom: 0rem;
  margin: auto 0;
}

.results-eligibility-description{
  font-size: 1.5;
  color: $dark-gray;
  font-weight: 300;
  font-family: $standard;
  max-width: 50rem;

  &.visa{
    padding-left: 0rem;
  }
}

.be-ul-description{
  margin-top: 30px;
}

.results-description{
  font-size: 1rem;
  color: $dark-gray;
  font-weight: 300;
  font-family: $standard;
  max-width: 50rem;

  &.support{
    max-width: 100%;
  }
}

.vc__application-summary-note {
  color: $blue;
}

.results-description > .content > ul >li {
  background: url('/images/green-check.svg') no-repeat top left;
  margin-left: -2.5rem;
  padding: 0px 0 6px 30px;
  list-style: none;
}

.vc__results-summary-req {
  font-family: $standard;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: rgba(0,0,0,0.87);
  width: 300px;
}

.vc__results-summary-res {
  color: rgba(0,0,0,0.87);
  font-family: $standard;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  width: 26rem;
}

.vc__results-summary-check {
  font-size: 1.7rem !important;
  margin-right: max($numbers: 30px);
  color: #34A853;
}

.vc__results-summary-x {
  font-size: 1.7rem !important;
  color: #EA4335;
}

.vc__results-summary-heading {
  color: #5C5C5C;
  font-family: $standard;
  font-size: 0.875rem;;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: 16px;
}

.vc__summary-heading-req-width {
  width: 300px;
}

.vc__summary-heading-res-width {
  width: 405px;
}

.results-permit-briefcase{
  color: $red;
}

.results-permit-clock{
  color: $blue;
}

.results-permit-home{
  color: $green;
}

.about-permit-heading{
  font-size: 0.8rem;
  margin-bottom: 0rem;
  margin-top: 2px;
  letter-spacing: 1px;
  font-weight: 500;
}

.results-permit-heading-type{
  color: $dark-gray;
  font-weight: 300;
  font-family: $headings;
}

.results-permit-heading{
  color: $dark-gray;
  font-weight: 300;
  font-family: $headings;
}

.results-visa-processing-bar{
  -webkit-print-color-adjust: exact;
  max-width: 100% !important;
}

.results-document-bar{
  height: .75rem;
  width: 100%;
  background-color: $blue;
}

.results-in-country-time-bar{
  height: .75rem;
  width: 100%;
  background-color: $red;
}

.results-government-bar{
  height: .75rem;
  width: 100%;
  background-color: $yellow;
}

.results-visa-bar{
  height: .75rem;
  width: 100%;
  background-color: $green;
}

.results-total-processing{
  font-size: 3rem;
  color: $dark-gray;
  font-weight: 400;
  font-family: $headings;
  margin-bottom: 0.25rem;
}

.results-processing-weeks{
  color: $dark-gray;
  font-size: 1.5rem;
  font-family: $headings;
  margin-top:10px;
}

.results-about-processing-weeks{
  font-weight: 500;
  letter-spacing: .1rem;
  font-family: $standard;
  color: $dark-gray;
  line-height: 16px;
  font-size: 10px;
  padding-right: 0.25rem;
}

.results-processing-timer{
  color: $yellow;
}

.copy-input{
  border: none;
  background-color: $white;
  font-size: .75rem;
  color: $dark-gray;
  font-family: $standard;
  font-weight: 300;
  box-shadow: none;
  padding: 0rem;
  padding-bottom: 1rem;
  margin-left: 0rem;

  &:focus{
    outline: 0;
  }
}

.copy-input-text-area{
  height:0px;
  width: 0px;
  margin: 0rem;
  padding: 0rem;
  border-color: $white;
  color: $white;
}

.results-copied-check{
  color: $green;
  font-size: 1rem !important;
  margin-right: 0.25rem;
}

.results-processing-time-container{
  margin-top: 3rem;
  padding-bottom:1rem;
}

.estimated-processing-time-container{
  margin-top: 3rem;
}

.results-start-date-calendar{
  color: $blue;
}

.results-start-date-container{
  padding-top:3rem;
}

.results-start-date-widget-container{
  position: absolute;
  padding-bottom: 10rem;
}

.vc_results-start-date-section {
  height: auto;
  width: auto;
}

.results-indicative-start-date-container{
  height: 15rem;
  width: 45%;
}

.results-start-date-widget-row{
  position: absolute;
  top: 3.25rem;
  width: 19rem;
  border-color: $lighter-gray;
}

.results-start-date-widget-date{
  position: absolute;
  background-color: $white;
  top:1rem;
  height:6.5rem;
  width:6.5rem;
  left: 2rem;
  border-radius: 2px;
  border: 1px solid $blue;
  box-shadow: $standard-dropshadow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform .5s ease;

  &.next{
    height:5.5rem;
    width:5.5rem;
    top:1.5rem;
    left: 11.5rem;
    border: 1px solid $lighter-gray;
  }

  &:hover{
    transform: scale(1.1);
  }
}

.vc__results-start-date-button {
  // Fix the button width to the Datepicker label
  .MuiIconButton-label {
    .btn.btn-primary {
      display: flex;
      align-items: center;
      width: 100%;
      height: 58px; // this height is slightly smaller than the usual 70px due to additional padding from the date picker component
    } 
  }
  
  &.MuiFormControl-root {
    display: inline-block;
  }
  
  .MuiTouchRipple-root {
    display: none;
  }
  
  .MuiInputAdornment-positionEnd {
    margin-left: 0;
    display: block;
  }

  .MuiButtonBase-root.MuiIconButton-root {
    margin-top: 1rem;
    padding: 0px;
  }
}

.results-start-date-widget-circle{
  height: .6rem;
  width: .6rem;
  background-color: $blue;
  border-radius: 100%;
}

.results-start-date-widget-day{
  color:$dark-gray;
  font-size: 1.5rem;
  font-family: $headings;
  margin-bottom: -3px;
  padding-top: 5px;
}

.results-start-date-widget-month{
  color:$dark-gray;
  font-size: .6rem;
  font-family: $standard;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.results-start-date-widget-recommended{
  color:$dark-gray;
  font-size: .6rem;
  font-family: $standard;
  font-weight: 400;
  letter-spacing: 1px;
  position: absolute;
  bottom:-1.5rem;
}

.results-outline-button {
  cursor: pointer;
  background-color: $white;
  color: $blue;
  border: 1px solid $light-gray;
  border-radius: 0.25rem;
  font-size: .8rem;
  letter-spacing: 1px;

  &:hover{
    box-shadow: 0px 3px 4px -2px $light-gray;
  }
}

.offer-acceptance-date-outline-button {
  cursor: pointer;
  background-color: $blue;
  color: $blue;
  border: 1px solid $light-gray;
  border-radius: 0.25rem;
  font-size: .8rem;
  letter-spacing: 1px;

  &:hover{
    box-shadow: 0px 3px 4px -2px $light-gray;
  }
}

.MuiInputBase-input{
  display: none !important;
}

.MuiIconButton-root {
    color: $blue !important;
    padding: 0.3rem 1.5rem;
    overflow: hidden !important;
    font-size: .75rem !important;
    text-align: center;
    transition: none !important;
    border-radius: 0% !important;
    letter-spacing: 1px;
    background-color: transparent !important;
    margin-right:.5rem !important;

    &:focus {
      outline:none !important;
    }
}

.acceptance-date-MuiIconButton-blue {
  color: $white !important;
  overflow: hidden !important;
  font-size: .75rem !important;
  text-align: center;
  transition: none !important;
  border-radius: 0% !important;
  letter-spacing: 1px;
  background-color: $blue !important;
  margin-right:.5rem !important;

  &:focus {
    outline:none !important;
  }
}

.MuiPickersDay-day{
  padding: 0rem !important;
  font-size: 1rem !important;
  transition: none !important;
  letter-spacing: 1px;
  background-color: none !important;
  margin-right:0.1rem !important;
  border-radius: 100% !important;
}

.MuiPickersDay-daySelected {
  color: #fff !important;
  font-weight: 500 !important;
  background-color: $blue !important;
}

.MuiFormControl-root{
  display: block !important;
}

.MuiInput-underline:before{
  border: none !important;
}

.results-attach-file{
  color:$yellow;
}

.results-attachment{
  color: $light-gray;
  font-size: 4.5rem !important;
  margin:auto;

}

.results-attachment-name{
  color:$dark-gray;
  font-size:0.75rem;
}

.results-attachment-link{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 1rem 1rem;
  border-radius: 1rem;
  padding: 0rem 0.25rem;
  transition: transform .5s ease;

  &:hover{
    text-decoration: none;
    transform: scale(1.1);
  }
}

.results-expand-more{
  color: $blue;
  font-size: 2rem !important; // TODO - REMOVE !IMPORTANT (FOR ALL)
}

.MuiExpansionPanelSummary-expandIcon{
  position: absolute !important;
  left: 0rem !important;
  padding: 0rem !important;
}

.MuiExpansionPanelSummary-content {
  margin: 0.5rem !important;
}

.MuiFormLabel-root{
  color: $white !important;
}

.results-sections-title {
  font-weight: 300;
  padding-left: 1rem;
  font-family: $headings;
  font-size: 2rem;
  color: $dark-gray;
}

.result-section-container {
  border-top: 1px solid $light-gray !important;
  margin-bottom: 1rem;
}

.MuiExpansionPanelDetails-root{
  padding-left: 0rem !important;
  padding-top: 1.5rem !important;
  border-bottom: 1px solid $light-gray;
}

.results-start-date-loading{
  padding-top: 5rem;
}


@media only screen and (max-width: 800px) {
  .results-success-check{
    font-size: 3rem !important;
  }

  .results-failure-x{
    font-size: 3rem !important;
  }

  .results-eligibility-heading{
    font-size: 2rem;
  }

  .results-eligibility-description{
    font-size: 1.5;
    padding-left: 0rem;
  }

  .results-permit-briefcase{
    color: $red;
  }

  .results-permit-clock{
    color: $blue;
  }

  .results-permit-home{
    color: $green;
  }

  .about-permit-heading{
    font-size: 0.75rem;
    margin-bottom: 0rem;
    margin-top: 5px;
  }

  .results-permit-heading-type{
    font-size: 1.5rem;
    padding-bottom: .25rem;
  }

  .results-permit-heading{
    font-size: 1.5rem;
    padding-bottom: 2rem;
  }

  .results-processing-weeks{
    color: $dark-gray;
    font-size: .75rem;
    font-weight: 300;
  }

  .results-about-processing-weeks{
    font-size: .5rem;
    letter-spacing: 0px;
  }

  .estimated-processing-time-container{
    margin-top: 0rem;
  }

}

@media print{
  .results-close-button{
    display: none !important;
  }

  .vc_results-return-button {
    display: none;
  }

  .navigation-help {
    display: none !important;
  }

  .results-outline-button{
    display: none !important;
  }

  .requirement-question-button{
    display: none !important;
  }

  .asessment-result-info{
    margin-top: 0rem;
  }

  .vc_results-return-button{
    display: none !important;
  }

  .vc__hide-app-summary-pdf {
    display: none;
  }
}
