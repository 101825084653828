//fonts
$standard: 'Roboto', sans-serif;
$headings: Product-Sans;
$headings-bold: Product-Sans-Bold;

//brand colors
$dark-gray: #757575;
$blue: #1a73e8;
$light-blue: #97b2de;
$light-gray: #d1d1d1;
$white: #ffffff;
$lighter-gray:#f0f0f0;
$red: #DB4437;
$yellow:#f4b400;
$green:#0F9D58;
$black: #000000;
$gray:#858585;
$darker-gray: #4f4f4f;

//card drop shadow
$standard-dropshadow: 0 1px 1px 0 rgba(0,0,0,0.08), 0 2px 1px -1px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.10) !important;

