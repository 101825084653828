.vc__icon-hidden {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  max-width: 0;

  &.transition {
    max-width: 50px;
    transition: all 0.8s;
  }
}

.sidebar {
  height: 100vh;
  width: 260px;
  position: fixed;
  z-index: 1;
  right: 0;
  background-color: white;
  padding-inline-start: 0;
  list-style: none;
  border-top: 1px solid $light-gray;
  border-left: 1px solid $light-gray;
  margin-top: -.5rem;

  .sidebar-scroll{
    max-height: 90%;
    overflow-y: scroll;
  }

  .question-section{
    text-transform: uppercase;
    color: $white;
    line-height: 16px;
    font-size: 10px;
    font-weight: 500;
    font-family: $standard;
    padding: .25rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid $white;
    background-color: $blue;

    &.specific {
      margin-top: -1rem;
    }
  }

  li{
    padding: 15px;
    margin-top: -16px;
    padding-top: 20px;
    padding-bottom: 0;
    text-transform: uppercase;
    line-height: 16px;
    font-size: 10px;
    color:$dark-gray;
    cursor: pointer;

    &.current{
      border-left: 3px solid $blue;
    }

    &:hover{
      background: $lighter-gray;
    }

    &.finished {
      background: $lighter-gray !important;
      cursor: auto;
    }

    &.visa{
      &:hover{
        background: none;
      }
    }

    &.disabled{
      color: $light-gray;
      cursor: auto;

      &:hover{
        background: none;
      }
    }

    .step {
      margin-top: 2px;
      margin-bottom: 10px;

      h6 {
        display: inline-block;
        letter-spacing: 1.50px;
        font-family: $standard;
        font-size: 10px !important;
      }

      .current {
        font-weight: 900;
      }

      .checkmark-icon {
        fill: $green;
        margin-top: -5px;
        margin-right: 10px;
      }

      .lock-icon {
        color: $dark-gray !important;
        font-size: 18px !important;
        margin-right: 10px !important;
        margin-top: -3px !important;
      }
    }
  }

  li:first-child {
    margin-top: 0;
  }
}

.reset-destination-heading{
  font-size: 2.5rem;
  color: $dark-gray;
  font-family: $headings;
  margin-bottom: 0rem;
}

.reset-destination-text{
  font-size: 1.5rem;
  color: $dark-gray;
  font-family: $headings;
  margin-bottom: 0rem;
  padding: 1rem;
  text-align: center;
}

.reset-destination-link{
  font-family: $standard;
  color: $red;

  &.hover{
    color:$red;
  }
}

.reset-modal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1200px){
  .sidebar {
    width: 220px;
  }
}

@media only screen and (max-width: 800px){

  .sidebar {
    width: 160px;

    li {
      color: $dark-gray;

      .step {
        margin: 2px;


        h6 {
          font-size: 8px !important;
        }

        .current {
          font-family: $standard;
          font-size: .625rem;
          color: $darker-gray;
          letter-spacing: .1rem;
          line-height: 1rem;
        }

        .checkmark-icon {
          fill: $green;
          margin-top: -5px;
        }
      }
    }

    li:hover {
      background: none;
    }

    li:first-child {
      margin-top: 0;
    }
  }

  .reset-destination-link{
    font-size: 0.75rem;
  }

  .reset-modal{
    width: 90%;
  }
}

@media only screen and (max-width: 600px){

  .sidebar {
    display: none;
  }
}

