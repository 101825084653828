.session-expired-background{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0rem;
  background-color: $white;
  opacity: 0.5;
  z-index: 1400;
  padding: 1rem;
}

.session-expired-message-container{
  z-index: 1500;
  position: relative;
  height: 90vh;
  width: 100vw;
}

.session-expired-message{
  background-color: $white;
  border: 1px solid $lighter-gray;
  box-shadow: $standard-dropshadow;
  border-radius: 2rem;
  padding: 6rem;
  font-family: $headings;
  color: $gray;
  letter-spacing: 2px;
}

@media only screen and (max-width: 800px){
  .session-expired-message{
    padding: 6rem 2rem;
  }
}
