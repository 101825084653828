.horizonal-stepper {
  height:0.4rem;
  width: 25%;

  &.red{
    background-color: $red;
  }
  &.blue{
    background-color: $blue;
  }
  &.yellow{
    background-color: $yellow;
  }
  &.green{
    background-color: $green;
  }
}

.horizonal-stepper-container{
  height:0.4rem;
  position: fixed;
  top: 0rem;
  z-index: 1300;

  &.cover{
    z-index: 1400;
    background-color: $white;
    right: 0rem;
    transition-timing-function: ease-in;
    transition: width 1.3s;
  }
}
