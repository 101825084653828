.landing-page-container {
  height: 90vh;
  width: 100vw;
  flex-direction: column;
  background-image: url('/images/gvc.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

//ANNOUNCEMENT STUFF

.vc__announcement-icon {
  font-size: 3rem !important;
}

.vc__announcement-title {
  text-transform: uppercase;
  font-weight: 500;
}

.vc__announcement-text {
  font-family: $standard;
  font-weight: 300;
  text-align: left;
  font-size: large;
}

.vc-announcement__text > p {
  margin:auto;
}

.log-in-screen {
  height: 100vh;
}

.retry-login-icon{
  color: $red;
  font-size: 1.25rem !important;
}

.retry-login-text {
  font-family: $standard;
  font-weight: 300;
  font-size: 0.8rem;
  color: $red;
  padding: .1rem;
}

.retry-login {
  cursor: pointer;
  transition: transform .5s ease;

  &:hover{
    transform: scale(1.1);
  }
}

.landing-page-button {
  background-color: $blue; 
  color:$white;
  font-size: 0.8rem;
  letter-spacing: 1px;
  padding: 1rem 2.5rem;
  box-shadow: 0px 3px 4px -2px $light-gray;
  border: none;
  border-radius: .2rem;
  font-family: $standard;

  &:hover{
    box-shadow: 0px 5px 6px -2px $light-gray;
    color: $white !important;
  }

  &:disabled{
    background-color: $light-gray;
    opacity: 1;

    &:hover{
      box-shadow: 0px 3px 4px -2px $light-gray;
    }
  }
}

.landing-page-text{
  font-family: $standard;
  font-weight: 500;
  color: $red;
  font-size: 1rem;
}

.landing-page-title{
  font-family: $standard;
  font-weight: 300;
  font-size: 3rem;
  text-align: center;
  
}

.blue-chevron-destination {
  color: $blue;
  font-size: 2.5rem !important;
}

// The react-select container
.destination-react-select-container {
  width: 30%;
  max-width: 40rem;
  min-width: 26rem;
  margin-bottom: 1rem;
}

// The border of the clickable area
.destination-react-select__control.css-yk16xz-control,
.destination-react-select__control--is-focused.css-1pahdxg-control,
.destination-react-select__control--menu-is-open.css-1pahdxg-control {
  border-color: $blue;
  border-radius: 0%;
  height: 4rem;

  &:hover {
    border-color: $blue;
  }
}

// Hide the indicator separator
.destination-react-select__indicator-separator {
  display: none;
}

// The placeholder text for react-select
.destination-react-select__placeholder {
  color: $light-gray;
  font-size: 1.6rem;
  font-weight: 300;
}

// The value shown when selected
.destination-react-select__single-value {
  color: $dark-gray;
  font-size: 1.5rem;
  font-weight: 300;
}

// No value selected yet, dropdown options when hovering over them
.destination-react-select__option.destination-react-select__option--is-focused.css-1n7v3ny-option {
  background-color: $lighter-gray;
  color: $dark-gray;
}

// No value selected yet, dropdown options when not hovering over them
.destination-react-select__option.css-yt9ioa-option {
  background-color: $white;
  color: $dark-gray;
}

// The dropdown options when not hovering over them
.destination-react-select__option.destination-react-select__option--is-selected.css-9gakcf-option {
  background-color: $white;
  color: $dark-gray;
  cursor: pointer;
}

// The dropdown options when you are hovering over them
.destination-react-select__option.destination-react-select__option--is-focused.destination-react-select__option--is-selected.css-9gakcf-option {
  background-color: $lighter-gray;
  color: $dark-gray;
  cursor: pointer;
}

@media only screen and (min-width: 3000px) {
  .landing-page-container {
    background-size:70% auto;
  }
}

@media only screen and (max-width: 1024px) {
  .landing-page-container {
    background-size:180%;
  }

  .landing-page-title{
    font-size: 2.5rem;
  }

  .landing-page-button {
    padding: 0.5rem 1.5rem;
  }
}

@media only screen and (max-width: 800px) {
  .landing-page-title{
    font-size: 2rem;
  }
}
