.requirement-question-container {
  flex-direction: column;
  width: 50rem;
  height: 90vh;

  &.requirement-group-top {
    padding-top: 12vh;
    height: auto;
  }

  &.requirement-group-top-remove-padding {
    padding-top: 0vh;
    height: auto;
  }

  &.requirement-group-bottom {
    padding-top: 4vh;
    height: auto;
  }
}

.vc__requirement-animation-wrapper {
  max-height: 60vh;
  flex-direction: row;
  transition: max-height 1s ease;
}

.vc__requirement-animation {
  animation-name: fadeInUp;
  animation-duration: 700ms;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    }
}

.vc__requirement-footnote {
  color:$dark-gray;
  font-weight: 300;
  font-family: $standard;
  letter-spacing: 0.016rem;
  line-height: 1.3rem;
  font-size: .8rem;
}

.requirement-back-link {
  color: $blue;
  font-family: $standard;
  font-size: .9rem;
  letter-spacing: 1px;
  padding: 0rem;

  &:hover{
    text-decoration: none;
  }

  &.hide {
    color:$white;
  }
}

.numeric-requirement-prefix{
  font-size: 4vw;
  color: $blue;
  margin-right: 1rem;
  font-weight: 600;
}

.numeric-input-container{
  border-bottom: 1px solid $dark-gray;
  height: 5rem;
  width: 50rem;
}

.requirement-numeric-input {
  border: none;
  font-size: 4vw;
  font-weight: 600;
  font-family: $headings-bold;
  color: $darker-gray;
  width: 50rem;
}

.vc__requirement-question {
  color: $dark-gray;
  font-weight: 300;
  font-family: $standard;
  font-size: 2.5vw;
}
.error-hint-message {
  color: $red;
  margin-left: 3%;
  font-family: $headings-bold;
}

.blue-chevron{
  color: $blue;
  font-size: 4vw;

  &.disabled {
    color: $white;
  }
}

.MuiSvgIcon-root {
  &.blue-chevron{
    font-size: 4vw;
  }
}

// font has odd behaviour when not set a !important
.MuiFormControlLabel-label {
  color:$dark-gray;
  font-size: 1.2rem !important;
  font-family: $standard !important;
}

.requirement-description{
  color:$dark-gray;
  font-weight: 300;
  font-family: $standard;
  letter-spacing: 0.016rem;
  line-height: 1.3rem;
}

.requirement-label{
  color: $white;
  opacity: 0;
  font-size: 1px;
  font-family: $standard;
  line-height: 0rem;
  margin-bottom: 0rem;
}

.requirement-numeric-input::placeholder {
  font-family: $headings-bold;
  color:$light-gray;
}

.requirement-summary{
  color: $dark-gray;
  font-weight: 300;
  font-family: $standard;
  font-size: 2.5rem;
  width: 50rem;
  padding-top: 3rem;
  margin-bottom: 0rem;
}

.requirement-summary-container{
  flex-direction: column;
  width: 50rem;

  .requirement-question-container {
    padding-right: 0vw;
  }

  .requirement-group-top{
    padding-top: 2rem;
  }
}

input:focus {
  outline: none;
}

// Relates to the yes-no selection on valid-visa page.
label{
  margin: 0rem !important; // requires !important to align with requirement title
}

// The title of the requirement. Bottom margin needs to be set, as text spacing will be odd.
.requirement-name{
  color: $dark-gray;
  text-transform: uppercase;
  font-size: .9rem;
  font-weight: 500;
  letter-spacing: .08rem;
  margin-bottom: 0rem !important;
  margin-bottom: -0.75rem;
}

.requirement-react-select-container {
  border-radius: 0rem;
  border-bottom: 1px solid $dark-gray;
  height: 5rem;
  width: 100%;
}

// Hide the indicator separator
.requirement-react-select__indicator-separator {
  display: none;
}

.requirement-react-select__input{
  font-size: 4vw;
  font-weight: 600;
  height: 4rem;
  color: $darker-gray;
  font-family: $headings;
}

// Box shadow and border need to be overwritten. Otherwise shows a box around the option box.
.requirement-react-select__control{
  box-shadow: none !important;
  height: 4rem;
  border: none !important;
}

// The placeholder text for react-select.
.requirement-react-select__placeholder {
  font-size: 4vw;
  font-weight: 500;
  color: $light-gray !important; // colour will default to black without this.
  font-family: $headings-bold;
  white-space:nowrap;
}

.requirement-react-select__control-menu-list{
  background-color: none;
}

// set max height to the drop-down box to prevent overflow.
.requirement-react-select__menu-list.css-1ml51p6-MenuList {
  max-height: 13rem;
}

// No value selected yet, dropdown options when hovering over them
.requirement-react-select__option.requirement-react-select__option--is-focused.css-1n7v3ny-option {
  background-color: $lighter-gray;
  color: $dark-gray;
}

// No value selected yet, dropdown options when not hovering over them
.requirement-react-select__option.css-yt9ioa-option {
  background-color: $white;
  color: $dark-gray;
}

// The dropdown options when not hovering over them
.requirement-react-select__option.requirement-react-select__option--is-selected.css-9gakcf-option {
  background-color: $white;
  color: $dark-gray;
  cursor: pointer;
}

// The dropdown options when you are hovering over them
.requirement-react-select__option.requirement-react-select__option--is-focused.requirement-react-select__option--is-selected.css-9gakcf-option {
  background-color: $lighter-gray;
  color: $dark-gray;
  cursor: pointer;
}

.requirement-assessment-link {
  color: $blue;
  font-family: $standard;
  font-size: .9rem;
  letter-spacing: 1px;

  &:hover{
    text-decoration: none;
  }
}

// The value shown when selected
.requirement-react-select__single-value {
  font-size: 4vw;
  font-weight: 600;
  color:$darker-gray !important;
  font-family: $headings-bold;
}

.dropdown-indicator{
  font-size: 2rem;
  padding-top: .75rem;
  color:$blue;
}

.requirement-question-button {
  background-color: $blue;
  color:$white;
  font-size: 0.8rem;
  letter-spacing: 1px;
  box-shadow: 0px 3px 4px -2px $light-gray;
  border: none;
  border-radius: .2rem;
  font-family: $standard;
  min-width: 170px;
  height: 44px;

  &:hover {
    box-shadow: 0px 5px 6px -2px $light-gray;
    color: $white;
  }

  &:disabled {
    color: $gray;
  }
}

.requirement-question-button:disabled{
  background-color: $lighter-gray;
  color: $gray;
  box-shadow: none;

  &:hover{
    box-shadow: none;
    background-color: $lighter-gray;
    box-shadow: none;
  }
}

.requirement-react-select__control--is-disabled{
  background-color: $white !important;
}

.requirement-react-select__single-value--is-disabled{
  color: $gray !important;
}

.requirement-question-lock{
  color: $gray;
  margin-top: 3rem;
  font-size: 4vw !important; // required so size of lock can be proportional to window size.
}

@media only screen and (max-height: 650px) {
  // set lower max height to the drop-down box to prevent overflow in shorter screens.
.requirement-react-select__menu-list.css-1ml51p6-MenuList {
  max-height: 5rem;
}
}


@media only screen and (max-width: 600px) {
  .requirement-question-container {
    width: 15rem;
  }

  .requirement-summary-container{
    width: 15rem;
  }
}

@media only screen and (max-width: 800px) {

  .requirement-description{
    font-size: .75rem;
  }

  .requirement-question-container {
    width: 25rem;

    &.requirement-group-top {
      height: auto;
    }

    &.requirement-group-bottom {
      height: auto;
    }
  }

  .requirement-summary-container{
    width: 25rem;
  }

  .numeric-requirement-prefix{
    font-size: 2.75vw;
    margin-right: 0.5rem;
    font-family: $headings;
    margin-top: .7rem;
  }

  .numeric-input-container{
    height: 3.5rem;
    width: 20rem;
  }

  .MuiSvgIcon-root {
    &.blue-chevron{
      font-size: 4vw;
    }
  }

  .requirement-numeric-input {
    height: 3rem;
    width: 20rem;
    font-size: 2.75vw;
    font-family: $headings;
  }

  .requirement-react-select-container{
    height: 4.1rem;
    width: 20rem;
  }

  .requirement-react-select__placeholder{
    font-size: 2.5vw;
  }

  .requirement-react-select__single-value{
    font-size: 2.5vw;
    font-family: $headings;
  }

  .requirement-react-select__input{
    font-size: 2.75vw;
    height: 2.5rem;
  }

  .vc__requirement-question {
    font-size: 2.0vw;
  }

  .requirement-question-button{
    font-size: 0.5rem;
    letter-spacing: 1px;
    padding: 0.5rem 1rem;
  }

  .requirement-assessment-link {
    font-size: 0.75rem;
    font-family: $standard;
  }

  .requirement-summary{
    font-size: 1.5rem;
  }

  .back-button{
    margin-left: -1.5em;
    margin-top: 1rem;
  }

  .requirement-question-lock{
    margin-top: 3rem;
    font-size: 2.75vw !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 800px) {
  .requirement-question-container {
    width: 30rem;


    &.requirement-group-top {
      height: auto;
    }

    &.requirement-group-bottom {
      height: auto;
    }
  }

  .requirement-summary-container{
    width: 30rem;
  }

  .numeric-input-container{
    width: 30rem;
  }

  .requirement-numeric-input {
    width: 30rem;
  }

  .requirement-react-select-container {
    width: 100%;
  }

  .requirement-summary{
    font-size: 2rem;
  }

  .requirement-description{
    font-size: .75rem;
  }

  .back-button{
    margin-left: -1.5em;
    margin-top: 1rem;
  }
}


@media only screen and (min-width: 1500px) {
  .numeric-requirement-prefix{
    font-size: 2vw;
  }

  .requirement-numeric-input {
    font-size: 2vw;
  }

  .vc__requirement-question {
    font-size: 2.5vw;
  }

  .blue-chevron{
    font-size: 2vw;
  }

  .MuiSvgIcon-root {
    &.blue-chevron{
      font-size: 2vw;
    }
  }

  .requirement-react-select__placeholder{
    font-size: 2vw;
  }

  .requirement-react-select__single-value{
    font-size: 2vw;
  }

  .requirement-react-select__input{
    font-size: 2vw;
  }

  .requirement-question-lock{
    margin-top: 3rem;
    font-size: 2vw !important;
  }
}

@media only screen and (min-width: 3000px) {
  .numeric-requirement-prefix{
    font-size: 1vw;
  }

  .requirement-numeric-input {
    font-size: 1vw;
  }

  .blue-chevron{
    font-size: 1vw;
  }

  .MuiSvgIcon-root {
    &.blue-chevron{
      font-size: 1vw;
    }
  }

  .requirement-react-select__placeholder{
    font-size: 1vw;
  }

  .requirement-react-select__single-value{
    font-size: 1vw;
  }

  .requirement-react-select__input{
    font-size: 1vw;
  }

  .vc__requirement-question {
    font-size: 2vw;
  }

  .requirement-question-lock{
    margin-top: 3rem;
    font-size: 1vw !important;
  }
}
