.MuiSnackbarContent-message{
  width: 100%;
}

.snackbar-close {
  display:flex;
  justify-content: space-between;
}

.snackbar-close-icon{
  cursor: pointer;
}

.snackbar-warning-content{
  background-color: $red !important;
  font-family: $standard !important;
  font-weight: 300 !important;
}