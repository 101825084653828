.vc__share-result-container {
  width: 85%;

  @media print {
    display: none;
  }
}

.vc__candidate-share-container {
  font-family: $standard;
  font-size: 1rem;
  color: $dark-gray;
  font-weight: 300;
  max-width: 70%;
}

.vc__ghire-copy-container {
  font-family: $standard;
  font-size: 1rem;
  color: $dark-gray;
  font-weight: 300;
}

.vc__ghire-copy-ineligible-width {
  max-width: 50%;
}

.vc__share-icon {
  color: $red;
}

.vc__white-button {
  background-color: $white;
  color:$blue;
  font-size: 0.8rem;
  letter-spacing: 1px;
  box-shadow: 0px 3px 4px -2px $light-gray;
  border-width: 0.1rem;
  border-color: $blue;
  border-radius: .2rem;
  font-family: $standard;
  padding: 0.7rem 1.7rem 0.7rem 1.7rem;

  &:hover {
    box-shadow: 0px 5px 6px -2px $light-gray;
    color: $blue;
  }
}

.vc__copied-hidden {
  overflow: hidden;
  display: inline-block;

  background-image: radial-gradient(circle, $blue, $blue);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size .5s, color .5s;

  &.transition {
    background-size: 100% 100%;
    color: #fff;
  }
}

.vc__copied-text {
  position: relative;
}

.vc__copy-text-animation {
  position: relative;
  white-space: nowrap;
  animation: floatText 0.5s 0s alternate ease-in-out forwards;
}

@keyframes floatText {
  from {
    right: -25%;
  }

  to {
    right: -15%;
  }
}

#check {
  fill: none;
  stroke: $white;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  -webkit-animation: draw 3s ease forwards;
  animation: draw 3s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.vc__clipboard-button-size {
  width: 200px;
  height: 50px;
}

.vc__start-date-switch {
  .MuiTypography-body1 {
    font-family: $standard;
    font-size: 0.9rem !important;
    color: $dark-gray;
    font-weight: 300;
  }
}