// banner for the notification on the landing page.
.landing-page-notifications {
  // bottom: 0rem;
  height: auto;
  width: 100vw;
  color: $white;
  box-shadow: inset 0 -2px 11px 0 rgba(0,0,0,0.2);
}

.vc__announcement {
  position: relative;
  // padding-top: 10px;
  right: 0;
  width: 100vw;
  padding-bottom: .8rem;
  padding-top: .8rem;
  color: white;
  box-shadow: inset 0 -2px 11px 0 rgba(0,0,0,0.2);
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
  height: auto;
  display: flex;
  align-items: center;

  &.alert {
    background-color: $yellow;
    color: $black;
    padding-left: revert;
    padding-right: revert;
    height: auto;
    width: auto;
    margin-bottom: auto;
    border-radius: 0%;
    border:0;
    size:auto;
  }

  &.warning {
    background-color: $red;
  }

  &.information {
    background-color: $green;
  }

  .carousel {
    width: 80%;
  }

  .carousel-control-prev,
  .carousel-control-next {
    font-size: calc(1.275rem + 0.3vw);
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1.25rem;
    color: inherit;
    cursor: pointer;
    font-weight: lighter;
    opacity: 1;
    text-shadow: none;
    font-size: 32px;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      color: inherit;
    }

    &:focus {
      outline: none;
    }
  }
}

.vc__announcement-item {
  display: flex;
  align-items: center;
  justify-content: left;
  line-height: 1.3em;
}