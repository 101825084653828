.carousel {
    width: 100%;
    margin: auto;
  }
  
  .carousel-inner {
    width: calc(100% - 60px);
    margin: auto;
  }
  
  .carousel-control-next,
  .carousel-control-prev {
    width: 30px;
    color: currentColor;
    opacity: 1;
    cursor: pointer;
  
    &:hover {
      opacity: 0.6;
      color: currentColor;
    }
  }