.help-and-support-container{
  flex-direction: column;
  width:50vw;
  margin: auto;
  text-align: center;
}

.help-section-panel{
  width: 50vw;
}

.faq-title{
  font-family: $standard;
  font-weight: 300;
  color: $dark-gray;
}

.faq-text{
  font-family: $standard;
  font-weight: 300;
  color: $dark-gray;
  font-size: 1rem;
}

.faq-link{
  font-family: $standard;
  font-weight: 300;
  color: $blue;
  font-size: 1rem;
}

.vc__help-and-support-close-icon {
  padding-top: 10px;
  padding-right: 20px;
}

.vc__close-button {
  cursor: pointer;
}

.faq-section-title {
  font-family: $headings;
  font-weight: 300;
  color: $dark-gray;
  font-size: 1.25rem;
  padding-left: 1rem;
}

.help-and-support-email-container{
  height: 6rem;
  border: 1px solid $light-gray;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.help-and-support-recruiter-title{
  font-family: $standard;
  color: $dark-gray;
  font-weight: 300;
  margin-bottom: 0.5rem;

  &.description{
    font-size: .9rem;
  }
}

.help-and-support-recruiter-email{
  font-family: $standard;
  color: $blue;
  font-weight: 300;
  font-size: .75rem;
}

.help-and-support-return-home{
  font-family: $standard;
  color: $blue;
  font-weight: 400;
  font-size: .9rem;
  margin-top:4rem;
  cursor: pointer;
}

.faq-content{
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.5rem;
}


.help-and-support-modal-content{
  flex-direction: column;
  height: 95vh;
  width: 90vw;
  overflow-y: scroll;
}

.help-and-support-modal{
  z-index: 1400 !important;
  overflow-y: scroll;
}

@media only screen and (min-width: 2000px) {
  .faq-content{
    font-size: 2rem;
    line-height: 3.25rem;
  }

  .help-and-support-return-home{
    font-size: 2rem;
  }

  .help-and-support-recruiter-email{
    font-size: 1.5rem;
  }

  .help-and-support-recruiter-title{
    &.description{
      font-size: 2rem;
    }
  }

  .faq-section-title{
    font-size: 2.25rem;
  }

  .faq-text{
    font-size: 2rem;
  }

  .faq-link{
    font-size: 2rem;
  }

  .help-and-support-email-container {
    height: 10rem;
  }

  .faq-title {
    font-size: 3rem;
    line-height: 4.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  // .help-and-support-container{
  //   width:60vw;
  // }

  // Extends the expansion panel beyond the modal size.
  // .help-section-panel{
  //   width: 70vw;
  // }
}

@media only screen and (max-width: 900px) {
  .faq-col{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
}

@media only screen and (max-width: 767px) {

  .faq-col{
    margin-top: 1rem;
  }
}
